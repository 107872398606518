<script setup>
import { useMainStore } from "@/store";
import Scrollbar from "smooth-scrollbar";
import DefaultHeader from "~/components/layout/Default/Header/DefaultHeader.vue";
import DefaultMenu from "~/components/layout/Default/Menu/DefaultMenu.vue";
import Loading from "~/components/global/Loading.vue";
const initAnim = useNuxtApp().$initAnim;

useHead({
  title: "",
  meta: [{ name: "description", content: `You are viewing the home page` }],
});

const isMenuOpen = ref(false);
const store = useMainStore();

onMounted(() => {
  if (!store.isMobile) {
    const el = document.querySelector(".inner-page");
    if (el) {
      Scrollbar.init(el, {
        damping: 0.07,
      });
    }
  }
  setTimeout(() => {
    initAnim();
  }, 1000);
});

function openMenu() {
  isMenuOpen.value = true;
  setTimeout(() => {
    if (!store.isMobile) {
      const el = document.querySelector(".main-menu");
      if (el && Scrollbar.get(el)) {
        Scrollbar.get(el).scrollTo(0, 0);
      }
    } else {
      document.body.scrollTo(0, 0);
    }
  }, 500);
}

function closeMenu() {
  isMenuOpen.value = false;
  setTimeout(() => {
    if (!store.isMobile) {
      const el = document.querySelector(".inner-page");
      if (el && Scrollbar.get(el)) {
        Scrollbar.get(el).scrollTo(0, 0);
      }
    } else {
      document.body.scrollTo(0, 0);
    }
  }, 400);
}
</script>

<template>
  <div class="default">
    <Transition name="fade">
      <Loading />
    </Transition>

    <Transition name="fadeMain">
      <section v-show="!store.mainLoaderActive && store.windowLoaded">
        <div class="inner-page">
          <DefaultHeader
            :openedMenu="isMenuOpen"
            @open-menu="openMenu"
            @close-menu="closeMenu"
          />
          <Transition name="fadeMain">
            <main v-show="!isMenuOpen">
              <NuxtPage />
            </main>
          </Transition>

          <Transition name="fadeMenu">
            <DefaultMenu v-show="isMenuOpen" />
          </Transition>
        </div>
      </section>
    </Transition>
  </div>
</template>

<style lang="scss" scoped>
.inner-page {
  width: 100%;
  padding: 0;
}

.fadeMain-enter-active {
  transition: opacity 0.3s;
  transition-delay: 0.5s;
}

.fadeMain-leave-active {
  transition: opacity 0.4s;
  transition-delay: 0s;
}

.fadeMain-enter-from,
.fadeMain-leave-to {
  opacity: 0;
}

.fade-enter-active {
  transition: opacity 0.4s;
  transition-delay: 0.4s;
}

.fade-leave-active {
  transition: opacity 0.4s;
  transition-delay: 0s;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.fadeMenu-enter-active {
  transition: opacity 0.5s;
  transition-delay: 0.5s;
}

.fadeMenu-leave-active {
  transition: opacity 0.5s;
  transition-delay: 0.4s;
}

.fadeMenu-enter-from,
.fadeMenu-leave-to {
  opacity: 0;
}

@supports (-webkit-touch-callout: none) {
  .inner-page {
    height: -webkit-fill-available;
    -webkit-overflow-scrolling: touch;
  }
}
</style>
