<script setup>
// const LanguageButton = defineAsyncComponent(() => import('~/components/global/LanguageButton.vue'))
const FlowButton = defineAsyncComponent(() =>
  import("~/components/global/FlowButton.vue")
);

const props = defineProps({
  openedMenu: Boolean,
});
const emit = defineEmits(["close-menu", "open-menu"]);

const onClickMenu = () => {
  const headerEl = document.querySelector(".header");
  if (!headerEl) return;

  if (props.openedMenu) {
    emit("close-menu");
    setTimeout(() => {
      headerEl.classList.remove("header-menu-open");
    }, 500);
  } else {
    emit("open-menu");
    setTimeout(() => {
      headerEl.classList.add("header-menu-open");
    }, 500);
  }
};
</script>
  
<template>
  <div>
    <header>
      <div class="header">
        <div class="header-logo">
          <NuxtImg
            class="logo-img"
            src="/image/header/logo.png"
            alt="logo"
            format="webp"
            data-anim="scalePicture"
          />
          <p class="logo-text" data-anim="animateTitle">
            {{ $t("Golden_Suisse") }}® — {{ $t("Swiss_wealth_protection") }}
          </p>
        </div>
        <div class="header-language" data-anim="animateLanguage">
          <!-- <LanguageButton /> -->
        </div>
        <div class="header-menu" data-anim="fadeIn" data-anim-delay="0.4">
          <svg
            class="menu"
            @click="onClickMenu"
            :class="{ 'menu-open': openedMenu }"
          >
            <line
              x1="0"
              x2="60"
              y1="1"
              y2="1"
              class="menu-rect"
              :class="{ 'menu-open-animation-top': openedMenu }"
            ></line>
            <line
              x1="60"
              x2="0"
              y1="11"
              y2="11"
              class="menu-rect"
              :class="{ 'menu-open-animation-bot': openedMenu }"
            ></line>
            <line
              x1="0"
              x2="40"
              y1="1"
              y2="1"
              class="menu-rect-mobile"
              :class="{ 'menu-open-animation-top': openedMenu }"
            ></line>
            <line
              x1="40"
              x2="0"
              y1="11"
              y2="11"
              class="menu-rect-mobile"
              :class="{ 'menu-open-animation-bot': openedMenu }"
            ></line>
          </svg>
        </div>
        <transition name="fade">
          <div class="header-company" v-show="!openedMenu">
            <p data-anim="fadeIn">
              <NuxtLink class="header-company-text" to="/company">{{
                $t("Company")
              }}</NuxtLink>
              <NuxtLink
                class="header-company-text"
                to="/products/gold-silver"
                >{{ $t("Products") }}</NuxtLink
              >
              <NuxtLink class="header-company-text" to="/account">{{
                $t("Personal_Account")
              }}</NuxtLink>
            </p>

            <div class="slog" data-anim="fadeIn">
              <NuxtImg
                class="company-img"
                src="/image/header/header-company.png"
                alt="company"
                format="webp"
              />
              <p class="text-slog">
                {{ $t("header_text_slog") }}
              </p>
            </div>
          </div>
        </transition>

        <transition name="fade">
          <div class="header-create-account" v-show="openedMenu">
            <FlowButton
              class="btn-create-account"
              label="Create_account"
              path="/account/register"
            />
          </div>
        </transition>
      </div>
    </header>
  </div>
</template>

<style lang="scss" scoped>
.header-menu-open {
  position: fixed;
  top: 0;
  width: 100%;
  height: 180px;
  z-index: 3;
  background-color: #101010;
  transition: height 1s linear;
}
.header {
  padding: 30px 60px 0 60px;
  display: grid;
  grid-template-areas: "logo language menu company";
  grid-template-columns: 350px 80px 1fr 50%;

  &-logo {
    grid-area: logo;
    display: inline-flex;
    .logo-img {
      width: 120px;
      height: 120px;
    }

    .logo-text {
      height: 50px;
      margin-left: 65px;
      width: 130px;

      font-weight: 700;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: -0.02em;
      color: #a0a0a0;
      text-transform: uppercase;
      text-decoration: none;
    }
  }

  &-logo:hover {
    text-decoration: none;
  }
  &-language {
    grid-area: language;
  }

  &-menu {
    grid-area: menu;
    display: flex;
    justify-content: flex-end;
    margin-right: 150px;

    .menu {
      width: 60px;
      height: 12px;
      cursor: pointer;
      position: relative;
      transition: all 0.4s ease-in-out;

      .menu-rect {
        stroke: white;
        stroke-width: 2px;
        transform: translateX(0) translateY(0) rotate(0);
        transition: all 0.4s ease-in-out;
      }
      .menu-rect-mobile {
        display: none;
        stroke: white;
        stroke-width: 2px;
        transform: translateX(0) translateY(0) rotate(0);
        transition: all 0.4s ease-in-out;
      }
    }
    .menu-open {
      height: 60px;

      .menu-open-animation-top {
        transform: translateX(60px) translateY(0) rotate(135deg);
      }
      .menu-open-animation-bot {
        transform: translateX(24px) translateY(-10px) rotate(45deg);
      }
    }
  }

  &-company {
    grid-area: company;
    display: flex;
    flex-direction: column;
    p {
      display: flex;
      flex-direction: column;
    }

    &-text {
      font-weight: 400;
      font-size: 80px;
      line-height: 80px;
      letter-spacing: -0.04em;
      color: #ffffff;
      margin-bottom: 0;

      text-decoration: none !important;
      transition: 0.2s;
      cursor: pointer;
    }

    &-text:hover {
      color: #fbc34a;
    }

    .slog {
      display: inline-flex;
      flex-direction: row;
      align-items: center;
      margin-top: 62px;
      margin-left: 6px;
    }
    .company-img {
      width: 124px;
      height: 40px;
    }
    .text-slog {
      width: 240px;
      margin-left: 60px;
      text-transform: uppercase;
      font-weight: bold;
      font-size: 14px;
      line-height: 16px;

      letter-spacing: -0.02em;
      color: #a0a0a0;
    }
  }

  &-create-account {
    grid-area: company;
    width: 100%;
    display: flex;
    justify-content: flex-end;

    .btn-create-account {
      width: 370px;
      height: 80px;
    }
  }
}

@media (max-width: 1599px) {
  .header-menu-open {
    height: 150px;
  }
  .header {
    grid-template-columns: 300px 80px 1fr 50%;

    &-logo {
      .logo-img {
        width: 90px;
        height: 90px;
      }

      .logo-text {
        height: 50px;
        margin-left: 28px;
      }
    }

    &-menu {
      justify-content: flex-end;
      margin-right: 85px;
    }

    &-company {
      &-text {
        font-size: 60px;
        line-height: 60px;
      }
      .slog {
        margin-top: 30px;
        margin-left: 0px;
      }
      .company-img {
        width: 88.8px;
        height: 30px;
      }
      .text-slog {
        margin-left: 20px;
      }
    }

    &-create-account {
      .btn-create-account {
        width: 290px;
        height: 80px;
      }
    }
  }
}

@media (max-width: 1279px) {
  .header-menu-open {
    height: 150px;
  }
  .header {
    padding: 30px 40px 0 40px;
    grid-template-columns: 250px 80px 1fr 50%;

    &-logo {
      .logo-img {
        width: 90px;
        height: 90px;
      }

      .logo-text {
        height: 50px;
        margin-left: 28px;
      }
    }

    &-menu {
      justify-content: flex-end;
      margin-right: 30px;
    }

    &-company {
      margin-left: 40px;
      &-text {
        font-size: 50px;
        line-height: 50px;
      }
      .slog {
        margin-top: 30px;
        margin-left: 0px;
      }
      .company-img {
        width: 124px;
        height: 40px;
      }
      .text-slog {
        margin-left: 60px;
      }
    }

    &-create-account {
      .btn-create-account {
        width: 354px;
        height: 80px;
      }
    }
  }
}

@media (max-width: 1023px) {
  .header-menu-open {
    height: 100px;
  }
  .header {
    padding: 20px 45px 0 45px;
    grid-template-areas:
      "logo menu"
      "company language ";
    grid-template-columns: 1fr auto;

    &-logo {
      .logo-img {
        width: 60px;
        height: 60px;
      }

      .logo-text {
        height: 50px;
        margin-left: 20px;
        margin-top: 5px;
      }
    }
    &-language {
      margin-top: 50px;
    }
    &-menu {
      justify-content: flex-end;
      margin-right: 0px;
      .menu {
        width: 40px;
        height: 12px;
        margin-top: 24px;

        .menu-rect {
          display: none;
        }
        .menu-rect-mobile {
          display: block;
        }
      }

      .menu-open {
        height: 40px;

        .menu-open-animation-top {
          transform: translateX(40px) translateY(0) rotate(135deg);
        }
        .menu-open-animation-bot {
          transform: translateX(18.5px) translateY(-10px) rotate(45deg);
        }
      }
    }

    &-company {
      margin-top: 50px;
      margin-left: 0;
      &-text {
        font-size: 60px;
        line-height: 60px;
      }
      .text-slog {
        margin-left: 30px;
      }
    }

    &-create-account {
      .btn-create-account {
        display: none;
      }
    }
  }
}

@media (max-width: 767px) {
  .header-menu-open {
    height: 80px;
  }
  .header {
    padding: 12.5px 15px 0 15px;

    &-logo {
      .logo-img {
        width: 55px;
        height: 55px;
      }

      .logo-text {
        height: 42px;
        margin-left: 15px;
        margin-top: 0px;
        width: 90px;

        font-size: 10px;
        line-height: 14px;
      }
    }
    &-language {
      margin-top: 40px;
    }

    &-menu {
      grid-area: menu;
      display: flex;
      justify-content: flex-end;
      .menu {
        margin-top: 12.5px;
      }
    }

    &-company {
      margin-top: 10px;
      margin-left: 5px;
      &-text {
        display: none;
      }
      .slog {
        flex-direction: column;
        align-items: flex-start;
      }
      .company-img {
        width: 95px;
        height: 30px;
      }
      .text-slog {
        margin-left: 0px;
        margin-top: 15px;
      }
    }
  }
}
</style>